<template>
  <div>
    <!-- hidden PageHeaderWrapper title demo -->
    <div>
      <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
        <a-form-item
          :label="$t('field.supplierCompanyCode')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <!-- <a-input
            v-decorator="[
              'supplierWarehouseData.supplierCompanyCode',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.supplierCompanyCode') },
                  { max: 100, message: this.$t('field.supplierCompanyCode') + '长度小于等于100' },
                ],
              },
            ]"
            name="supplierWarehouseData.supplierCompanyCode"
            v-model="supplierWarehouseData.supplierCompanyCode"
            :placeholder="'请输入' + this.$t('field.supplierCompanyCode')"
          /> -->
          <a-select mode="default" v-model="supplierWarehouseData.supplierCompanyCode">
            <a-select-option
              v-for="(item, index) in codeList.supplierCompanyCode"
              :key="index"
              :value="item.supplierCompanyCode"
            >[{{ item.supplierCompanyCode }}]-{{ item.supplierCompanyName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :label="$t('field.warehouseCode')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'supplierWarehouseData.warehouseCode',
              {
                rules: ([
                  { required: true, message: '请输入' + this.$t('field.warehouseCode') },
                  { max: 100, message: this.$t('field.warehouseCode') + '长度小于等于100' },
                  {type:'checkExists', existIdValue:supplierWarehouseData.warehouseId, checkField:'warehouseCode', idFieldName: 'warehouseId',tableName:'warehouse' }
                ]),
              },
            ]"
            name="supplierWarehouseData.warehouseCode"
            v-model="supplierWarehouseData.warehouseCode"
            :placeholder="'请输入' + this.$t('field.warehouseCode')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.warehouseName')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'supplierWarehouseData.warehouseName',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.warehouseName') },
                  { max: 100, message: this.$t('field.warehouseName') + '长度小于等于100' },
                ],
              },
            ]"
            name="supplierWarehouseData.warehouseName"
            v-model="supplierWarehouseData.warehouseName"
            :placeholder="'请输入' + this.$t('field.warehouseName')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.warehouseAddress')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'supplierWarehouseData.warehouseAddress',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.warehouseAddress') },
                  { max: 100, message: this.$t('field.warehouseAddress') + '长度小于等于100' },
                ],
              },
            ]"
            name="supplierWarehouseData.warehouseAddress"
            v-model="supplierWarehouseData.warehouseAddress"
            :placeholder="'请输入' + this.$t('field.warehouseAddress')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.warehouseContactName')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'supplierWarehouseData.warehouseContactName',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.warehouseContactName') },
                  { max: 100, message: this.$t('field.warehouseContactName') + '长度小于等于100' },
                ],
              },
            ]"
            name="supplierWarehouseData.warehouseContactName"
            v-model="supplierWarehouseData.warehouseContactName"
            :placeholder="'请输入' + this.$t('field.warehouseContactName')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.warehouseContactPhone')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'supplierWarehouseData.warehouseContactPhone',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.warehouseContactPhone') },
                  { max: 100, message: this.$t('field.warehouseContactPhone') + '长度小于等于100' },
                ],
              },
            ]"
            name="supplierWarehouseData.warehouseContactPhone"
            v-model="supplierWarehouseData.warehouseContactPhone"
            :placeholder="'请输入' + this.$t('field.warehouseContactPhone')"
          />
        </a-form-item>
        <!-- <a-form-item
          :label="$t('field.country')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-select mode="default" v-model="supplierWarehouseData.country" style="width: 400px">
            <a-select-option
              v-for="(item, index) in codeList.country"
              :key="index"
              :value="item.value"
            >{{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item> -->
        <!-- <a-form-item
          :label="$t('field.state')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'supplierWarehouseData.state',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.state') },
                  { max: 100, message: this.$t('field.state') + '长度小于等于100' },
                ],
              },
            ]"
            name="supplierWarehouseData.state"
            v-model="supplierWarehouseData.state"
            :placeholder="'请输入' + this.$t('field.state')"
          />
        </a-form-item> -->
        <!-- <a-form-item
          :label="$t('field.city')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'supplierWarehouseData.city',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.city') },
                  { max: 100, message: this.$t('field.city') + '长度小于等于100' },
                ],
              },
            ]"
            name="supplierWarehouseData.city"
            v-model="supplierWarehouseData.city"
            :placeholder="'请输入' + this.$t('field.city')"
          />
        </a-form-item> -->
        <a-form-item
          :label="$t('field.city')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-cascader :options="codeList.cityList" placeholder="Please select" @change="onChange" name="city" style="width:100%"/>
        </a-form-item>
      </a-card>
    </div>
  </div>
</template>

<script>
import { listSupplierCompany } from '@/api/system/supplierCompany'
export default {
  name: 'EditSupplierWarehouse',
  props: {
    supplierWarehouseData: {
      type: Object
    }
  },
  data () {
    return {
      codeList: {
        country: [{ label: '美国', value: 'USA' }, { label: '墨西哥', value: 'Maxico' }, { label: '巴西', value: 'Brazil' }],
        isValid: [],
        supplierCompanyCode: []
      }
    }
  },
  mounted () {
    const that = this
    listSupplierCompany({}).then(res => {
      that.codeList.supplierCompanyCode = res.data
    })
  },
  methods: {
    onChange (value) {
      console.log(value)
      this.$emit('cityValChange', value)
    }
  }
}
</script>
<style>
.ant-select{
  width:100%;
}
</style>
