var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-form-item',{attrs:{"label":_vm.$t('field.supplierCompanyCode'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-select',{attrs:{"mode":"default"},model:{value:(_vm.supplierWarehouseData.supplierCompanyCode),callback:function ($$v) {_vm.$set(_vm.supplierWarehouseData, "supplierCompanyCode", $$v)},expression:"supplierWarehouseData.supplierCompanyCode"}},_vm._l((_vm.codeList.supplierCompanyCode),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.supplierCompanyCode}},[_vm._v("["+_vm._s(item.supplierCompanyCode)+"]-"+_vm._s(item.supplierCompanyName)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.warehouseCode'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'supplierWarehouseData.warehouseCode',
            {
              rules: ([
                { required: true, message: '请输入' + this.$t('field.warehouseCode') },
                { max: 100, message: this.$t('field.warehouseCode') + '长度小于等于100' },
                {type:'checkExists', existIdValue:_vm.supplierWarehouseData.warehouseId, checkField:'warehouseCode', idFieldName: 'warehouseId',tableName:'warehouse' }
              ]),
            } ]),expression:"[\n            'supplierWarehouseData.warehouseCode',\n            {\n              rules: ([\n                { required: true, message: '请输入' + this.$t('field.warehouseCode') },\n                { max: 100, message: this.$t('field.warehouseCode') + '长度小于等于100' },\n                {type:'checkExists', existIdValue:supplierWarehouseData.warehouseId, checkField:'warehouseCode', idFieldName: 'warehouseId',tableName:'warehouse' }\n              ]),\n            },\n          ]"}],attrs:{"name":"supplierWarehouseData.warehouseCode","placeholder":'请输入' + this.$t('field.warehouseCode')},model:{value:(_vm.supplierWarehouseData.warehouseCode),callback:function ($$v) {_vm.$set(_vm.supplierWarehouseData, "warehouseCode", $$v)},expression:"supplierWarehouseData.warehouseCode"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.warehouseName'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'supplierWarehouseData.warehouseName',
            {
              rules: [
                { required: true, message: '请输入' + this.$t('field.warehouseName') },
                { max: 100, message: this.$t('field.warehouseName') + '长度小于等于100' } ],
            } ]),expression:"[\n            'supplierWarehouseData.warehouseName',\n            {\n              rules: [\n                { required: true, message: '请输入' + this.$t('field.warehouseName') },\n                { max: 100, message: this.$t('field.warehouseName') + '长度小于等于100' },\n              ],\n            },\n          ]"}],attrs:{"name":"supplierWarehouseData.warehouseName","placeholder":'请输入' + this.$t('field.warehouseName')},model:{value:(_vm.supplierWarehouseData.warehouseName),callback:function ($$v) {_vm.$set(_vm.supplierWarehouseData, "warehouseName", $$v)},expression:"supplierWarehouseData.warehouseName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.warehouseAddress'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'supplierWarehouseData.warehouseAddress',
            {
              rules: [
                { required: true, message: '请输入' + this.$t('field.warehouseAddress') },
                { max: 100, message: this.$t('field.warehouseAddress') + '长度小于等于100' } ],
            } ]),expression:"[\n            'supplierWarehouseData.warehouseAddress',\n            {\n              rules: [\n                { required: true, message: '请输入' + this.$t('field.warehouseAddress') },\n                { max: 100, message: this.$t('field.warehouseAddress') + '长度小于等于100' },\n              ],\n            },\n          ]"}],attrs:{"name":"supplierWarehouseData.warehouseAddress","placeholder":'请输入' + this.$t('field.warehouseAddress')},model:{value:(_vm.supplierWarehouseData.warehouseAddress),callback:function ($$v) {_vm.$set(_vm.supplierWarehouseData, "warehouseAddress", $$v)},expression:"supplierWarehouseData.warehouseAddress"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.warehouseContactName'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'supplierWarehouseData.warehouseContactName',
            {
              rules: [
                { required: true, message: '请输入' + this.$t('field.warehouseContactName') },
                { max: 100, message: this.$t('field.warehouseContactName') + '长度小于等于100' } ],
            } ]),expression:"[\n            'supplierWarehouseData.warehouseContactName',\n            {\n              rules: [\n                { required: true, message: '请输入' + this.$t('field.warehouseContactName') },\n                { max: 100, message: this.$t('field.warehouseContactName') + '长度小于等于100' },\n              ],\n            },\n          ]"}],attrs:{"name":"supplierWarehouseData.warehouseContactName","placeholder":'请输入' + this.$t('field.warehouseContactName')},model:{value:(_vm.supplierWarehouseData.warehouseContactName),callback:function ($$v) {_vm.$set(_vm.supplierWarehouseData, "warehouseContactName", $$v)},expression:"supplierWarehouseData.warehouseContactName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.warehouseContactPhone'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'supplierWarehouseData.warehouseContactPhone',
            {
              rules: [
                { required: true, message: '请输入' + this.$t('field.warehouseContactPhone') },
                { max: 100, message: this.$t('field.warehouseContactPhone') + '长度小于等于100' } ],
            } ]),expression:"[\n            'supplierWarehouseData.warehouseContactPhone',\n            {\n              rules: [\n                { required: true, message: '请输入' + this.$t('field.warehouseContactPhone') },\n                { max: 100, message: this.$t('field.warehouseContactPhone') + '长度小于等于100' },\n              ],\n            },\n          ]"}],attrs:{"name":"supplierWarehouseData.warehouseContactPhone","placeholder":'请输入' + this.$t('field.warehouseContactPhone')},model:{value:(_vm.supplierWarehouseData.warehouseContactPhone),callback:function ($$v) {_vm.$set(_vm.supplierWarehouseData, "warehouseContactPhone", $$v)},expression:"supplierWarehouseData.warehouseContactPhone"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.city'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-cascader',{staticStyle:{"width":"100%"},attrs:{"options":_vm.codeList.cityList,"placeholder":"Please select","name":"city"},on:{"change":_vm.onChange}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }