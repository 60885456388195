import request from '@/utils/request'
import qs from 'qs'

const baseUrl = '/api'

export function saveSupplierWarehouseData (parameter) {
  let url = baseUrl + '/system/supplierWarehouse/save'
  if (!parameter.supplierWarehouseId) {
    url = baseUrl + '/system/supplierWarehouse/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchSupplierWarehouse (parameter) {
  return request({
    url: baseUrl + '/system/supplierWarehouse/search?' + qs.stringify(parameter),
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listSupplierWarehouse (parameter) {
  return request({
    url: baseUrl + '/system/supplierWarehouse/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getSupplierWarehouseData (parameter) {
  return request({
    url: baseUrl + '/system/supplierWarehouse/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableSupplierWarehouse (parameter) {
  return request({
    url: baseUrl + '/system/supplierWarehouse/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableSupplierWarehouse (parameter) {
  return request({
    url: baseUrl + '/system/supplierWarehouse/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteSupplierWarehouse (parameter) {
  return request({
    url: baseUrl + '/system/supplierWarehouse/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
